import "../scss/index.scss";

const navbar = () => {
  const menu = document.getElementById("nav-menu");
  const opener = document.getElementById("navbar-toggler");
  const closer = document.getElementById("close-nav");

  opener.addEventListener("click", (event) => {
    event.stopPropagation();
    menu.classList.add("open");
    document.body.classList.add("menu-open");

  });
  closer.addEventListener("click", () => {
    menu.classList.remove("open");
    document.body.classList.remove("menu-open");
  });
}

const hoverCat = () => {
  // Only load the hover cats if we're large enough.
  if (window.screen.width < 768) {
    return;
  }

  const hoverCats = Array.from(document.querySelectorAll("[data-hover-cat]"));
  // Lazy load images
  hoverCats.forEach(img => {
    img.src = img.getAttribute("data-src");
  });

  const links = document.querySelectorAll("#menu-main-menu a");
  links.forEach((a,i) => {
    const cat = i % hoverCats.length;
    a.addEventListener("mouseover", () => {
      hoverCats[cat].classList.add("show");
    });
    a.addEventListener("mouseout", () => {
      hoverCats[cat].classList.remove("show");
    });
  })

}

const textRotator = () => {
  const rotators = Array.from(document.querySelectorAll("[data-rotating-text]"));
  const height = 1.125 * 1.5;
  let position = 0;
  setInterval(function() {
    position = (position + 1) % rotators.length;
    rotators.forEach(rotator => {
      rotator.style.transform = `translateY(${height * -position}rem)`;
    });
  }, 3000);
};

const navbarScrollSpy = () => {
  const els = document.querySelectorAll(".catlab-navbar .show-on-scroll");
  if (els.length) {
    window.addEventListener(
      "scroll",
      () => {
        if (window.scrollY > 300) {
          els.forEach(el => el.classList.add("show"));
        } else {
          els.forEach(el => el.classList.remove("show"));
        }
      },
      {passive: true}
    );
  }
};

// Seem to get flaky behavior from `DOMContentLoaded', so using jQuery 
jQuery(document).ready(navbar);
jQuery(document).ready(hoverCat);
jQuery(document).ready(textRotator);
jQuery(document).ready(navbarScrollSpy);
